@import url(https://fonts.googleapis.com/css?family=Oleo+Script);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --background-color: rgb(148, 140, 129);
}


/* leaflet */

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

#root {
    width: inherit;
    height: inherit;
}

.leaflet-container {
    width: 90%;
    height: 65vh;
    margin-left: 5%;
}


/* App ------------------------------------------------------------------ */

.app {
    height: 93vh;
    width: 100vw;
    background-color: rgb(148, 140, 129);
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    color: white;
}

.ligne {
    display: flex;
    height: calc(100% / 2);
}

.case {
    display: flex;
    width: 50%;
    height: 100%;
    color: white;
}

.case:hover {
    text-decoration: none;
}

.caseLink {
    border-radius: 10px;
    border: solid 1px white;
}

@media screen and (min-width: 1026px) {
    .caseLink {
        opacity: 0.4;
    }
    .caseLink:hover {
        opacity: 1;
    }
}

.titleCase {
    margin: auto;
    font-family: 'Oleo Script', sans-serif;
    font-size: 1.6em;
}

@media screen and (min-width: 768px) {
    .titleCase {
        font-size: 3em;
    }
}

.caseMap {
    position: relative;
    overflow: hidden;
}

.caseMap .titleCase {
    position: relative;
    z-index: 2;
    color: black;
}

.caseMap img {
    position: absolute;
    opacity: 0.8;
}

@media screen and (min-width: 768px) {
    .caseMap img {
        width: 100%;
        height: 100%;
    }
}

.caseList {
    position: relative;
    overflow: hidden;
}

.caseList .titleCase {
    position: relative;
    z-index: 2;
    color: black;
}

.caseList img {
    position: absolute;
    opacity: 0.8;
    width: 130%;
    height: 100%;
}

@media screen and (min-width: 768px) {
    .caseList img {
        width: 100%;
        height: 100%;
    }
}

.caseRegions {
    position: relative;
    overflow: hidden;
    background-color: white;
}

.caseRegions .titleCase {
    position: relative;
    z-index: 2;
    color: black;
}

.caseRegions img {
    position: absolute;
    opacity: 0.8;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 768px) {
    .caseRegions img {
        width: 100%;
        height: 100%;
    }
}


/* CarteHippodromes ------------------------------------------------------------------------ */

.bodyMap {
    background-color: #948c81;
    height: 93vh;
    width: 100%;
}

.titreCarte {
    text-align: center;
    font-size: 2em;
    padding: 5% 0;
    font-family: 'Oleo Script', sans-serif;
}

@media screen and (min-width: 540px) {
    .titreCarte {
        padding: 2% 0;
    }
}

.linkHippodrome {
    text-decoration: none;
    display: flex;
}

.lien {
    flex-direction: column;
}

.apercu {
    width: 200px;
}


/* Contact ------------------------------------------------------------------------ */

.divContact {
    display: flex;
    height: 93vh;
    background-color: rgb(148, 140, 129);
    background-color: var(--background-color);
    font-family: 'Lato', sans-serif;
}

.divContact2 {
    margin: auto;
    color: white;
}

.divContact2 a {
    color: white;
}

.divContact2 a:hover {
    color: white;
}

.textContact {
    font-size: 0.9rem;
}

@media screen and (min-width: 540px) {
    .textContact {
        font-size: 1.2rem;
    }
}


/* Footer ------------------------------------------------------------------------- */

.bodyFooter {
    height: 7vh;
    width: 100vw;
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 5;
}

@media screen and (min-width: 1025px) {
    .bodyFooter {
        display: none;
    }
}

.divFooter {
    width: calc(100% / 5);
    height: 7vh;
    display: flex;
    background-color: rgb(148, 140, 129);
    background-color: var(--background-color);
    border-top: solid 1px white;
    border-left: solid 1px white;
    border-right: solid 1px white;
}

.ecStyle {
    background-color: rgb(146, 93, 43);
}

.linkFooter {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
}

.colorLinkFooter {
    font-size: 0.7em;
    margin: auto;
}

@media screen and (min-width: 760px) {
    .colorLinkFooter {
        font-size: 1.4em;
    }
}


/* Header --------------------------------------------------------------------- */

.bodyHeader {
    display: none;
}

@media screen and (min-width: 1025px) {
    .bodyHeader {
        display: block;
    }
}

.navbar {
    height: 7vh;
    z-index: 100 !important;
    border-bottom: solid 1px white;
    color: white !important;
    border-bottom: solid 1px black;
}

.nav-link {
    z-index: 100 !important;
}


/* Hippodrome détail --------------------------------------------------------------- */

.fiche {
    background-color: rgb(148, 140, 129);
    background-color: var(--background-color);
    display: flex;
    height: 95vh;
    padding-top: 3%;
}

@media screen and (max-width: 1030px) {
    .fiche {
        flex-direction: column;
    }
}

.image {
    width: 50vw;
    padding-left: 5%;
}

.tailleImage {
    width: 50vw;
    border-radius: 10px;
}

@media screen and (max-width: 1030px) {
    .tailleImage {
        width: 90vw;
    }
}

.description {
    margin: auto;
    margin-top: 0;
    padding-left: 2%;
    padding-right: 2%;
    background-color: #c4c4c4;
    border-radius: 10px;
    width: 25vw;
    height: 80vh;
}

@media screen and (max-width: 1030px) {
    .description {
        margin-top: 0;
        margin-left: 5%;
        width: 85vw;
        height: 45vh;
    }
}

.titreCard {
    text-align: center;
    font-family: 'Oleo Script', sans-serif;
    color: rgb(27, 32, 37);
    font-size: 3em;
    margin-top: 10%;
    margin-bottom: 15%;
}

.long {
    font-size: 2em;
}

@media screen and (max-width: 1030px) {
    .titreCard {
        padding-left: 5%;
        margin-bottom: 0;
        margin-top: 0;
    }
}

.liCard {
    margin-bottom: 5%;
    list-style: none;
}

@media screen and (max-width: 1030px) {
    .liCard {
        margin-bottom: 0;
    }
}

.plusDePhotos {
    display: flex;
    padding-top: 2%;
    padding-bottom: 5%;
    background-color: #948c81;
}

@media screen and (max-width: 1030px) {
    .plusDePhotos {
        flex-direction: column;
    }
}

.tailleImagePlus {
    width: 40vw;
    border-radius: 10px;
}

@media screen and (max-width: 1030px) {
    .tailleImagePlus {
        width: 85vw;
        padding: 5%;
    }
}

.photoLarge {
    padding-top: 2%;
    padding-bottom: 5%;
    background-color: #948c81;
    padding-bottom: 20%;
}

@media screen and (min-width: 1025px) {
    .photoLarge {
        padding-bottom: 5%;
    }
}

.imageLarge {
    padding-left: 5%;
    padding-right: 5%;
}

.tailleImageLarge {
    width: 90vw;
}

.der {
    padding-bottom: 20%;
}

@media screen and (min-width: 1025px) {
    .der {
        padding-bottom: 5%;
    }
}


/* Liste ------------------------------------------------------------------------ */

.bodyListe {
    min-height: 93vh;
    background-color: #948c81;
    display: flex;
}

.liste {
    width: 100vw;
    padding-top: 2%;
    text-align: center;
}

.linkListe {
    margin: auto;
    width: 80%;
    height: 50px;
    display: flex;
    margin-bottom: 2%;
    background-color: rgb(179, 172, 172);
    color: black;
    font-size: 1.5em;
    font-family: 'Oleo Script', sans-serif;
}

@media screen and (min-width: 768px) {
    .linkListe {
        height: 75px;
        margin-bottom: 1%;
    }
}

.linkListe:hover {
    text-decoration: none;
    background-color: black;
    color: white;
}

.titleLinkListe {
    margin: auto;
}


/* Not Found -------------------------------------------------------------- */

.notFound {
    height: 93vh;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.divNotFound {
    margin: auto;
    color: white;
}

.imgExit {
    width: 100px;
}

.imgDoor {
    width: 100px;
}


/* Région ------------------------------------------------------------------------ */

.titleRegion {
    font-family: 'Oleo Script', sans-serif;
    font-size: 1.6em;
}

@media screen and (min-width: 768px) {
    .titleRegion {
        font-size: 2em;
    }
}

.ligneRegion {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .ligneRegion {
        flex-direction: row;
    }
}

.caseRegion {
    width: 100%;
    height: 100px;
    display: flex;
}

@media screen and (min-width: 768px) {
    .caseRegion {
        width: 50%;
        height: 200px;
    }
}

.hippo {
    margin: auto;
    width: 90%;
    height: 90%;
    border-radius: 10px;
    border: solid 2px white;
    background-color: rgb(160, 153, 153);
    display: flex;
}

.hippo:hover {
    background-size: cover;
    background-position: center;
}

.hippo span {
    margin: auto;
    color: white;
    font-size: 2em;
    font-family: 'Oleo Script', sans-serif;
}

.hippo:hover span {
    display: none;
}

.hippo_auteuil:hover {
    background-image: url(/static/media/auteuil.36783923.jpg);
}

.hippo_bacqueville:hover {
    background-image: url(/static/media/bacqueville.5a15cd8b.jpg);
}

.hippo_bernay:hover {
    background-image: url(/static/media/bernay.65c324aa.jpg);
}

.hippo_bihorel:hover {
    background-image: url(/static/media/bihorel.bbb34490.jpg);
}

.hippo_chantilly:hover {
    background-image: url(/static/media/chantilly.782f6cc1.jpg);
}

.hippo_chartres:hover {
    background-image: url(/static/media/chartres.8fa3046a.jpg);
}

.hippo_compiegne:hover {
    background-image: url(/static/media/compiegne.91d08b4c.jpg);
}

.hippo_dieppe:hover {
    background-image: url(/static/media/dieppe.507a1106.jpg);
}

.hippo_enghien:hover {
    background-image: url(/static/media/enghien01.a403a294.jpg);
}

.hippo_evreux:hover {
    background-image: url(/static/media/evreux.d2bd88bc.jpg);
}

.hippo_francheville:hover {
    background-image: url(/static/media/francheville01.13ea12da.jpg);
}

.hippo_fontainebleau:hover {
    background-image: url(/static/media/fontainebleau.2c75209e.jpg);
}

.hippo_gournay:hover {
    background-image: url(/static/media/gournay.0cdaa8cb.jpg);
}

.hippo_la_ferte:hover {
    background-image: url(/static/media/la_ferte-vidame01.251d7eac.jpg);
}

.hippo_le_neubourg:hover {
    background-image: url(/static/media/le_neubourg.2a1df604.jpg);
}

.hippo_les_andelys:hover {
    background-image: url(/static/media/les_andelys01.a9e2d90a.jpg);
}

.hippo_longchamp:hover {
    background-image: url(/static/media/longchamp.d057edd5.jpg);
}

.hippo_maisons:hover {
    background-image: url(/static/media/maisons_laffitte.6ab365f0.jpg);
}

.hippo_mauquenchy:hover {
    background-image: url(/static/media/mauquenchy.cb373b06.jpg);
}

.hippo_rambouillet:hover {
    background-image: url(/static/media/rambouillet01.a95dc214.jpg);
}

.hippo_saint_aubin:hover {
    background-image: url(/static/media/saint_aubin.4ee4ad4a.jpg);
}

.hippo_saint-cloud:hover {
    background-image: url(/static/media/saint-cloud.774d4693.jpg);
}

.hippo_vincennes:hover {
    background-image: url(/static/media/vincennes.0b6a6c64.jpg);
}

.hippo_angers:hover {
    background-image: url(/static/media/angers.a85f3912.jpg);
}

.hippo_cholet:hover {
    background-image: url(/static/media/cholet.f3a1a350.jpg);
}

.hippo_craon:hover {
    background-image: url(/static/media/craon.e31d003a.jpg);
}

.hippo_laval:hover {
    background-image: url(/static/media/laval.da04492e.jpg);
}

.hippo_le_lion:hover {
    background-image: url(/static/media/le_lion.0eae418c.jpg);
}

.hippo_meslay:hover {
    background-image: url(/static/media/meslay.ba08d121.jpg);
}

.hippo_argentan:hover {
    background-image: url(/static/media/argentan.d1eafa4d.jpg);
}

.hippo_cabourg:hover {
    background-image: url(/static/media/cabourg.223f6353.jpg);
}

.hippo_caen:hover {
    background-image: url(/static/media/caen.922cf261.jpg);
}

.hippo_clairefontaine:hover {
    background-image: url(/static/media/clairefontaine.55d9598b.jpg);
}

.hippo_deauville:hover {
    background-image: url(/static/media/deauville01.5ce7d55b.jpg);
}

.hippo_dozule:hover {
    background-image: url(/static/media/dozule.454ea9c2.jpg);
}

.hippo_graignes:hover {
    background-image: url(/static/media/graignes.464479a9.jpg);
}

.hippo_granville:hover {
    background-image: url(/static/media/granville01.f266d51c.jpg);
}

.hippo_le_mont:hover {
    background-image: url(/static/media/le_mont.a5cb60d9.jpg);
}

.hippo_lisieux:hover {
    background-image: url(/static/media/lisieux01.6c66aaf6.jpg);
}

.hippo_ranes:hover {
    background-image: url(/static/media/ranes.389a6672.jpg);
}

.hippo_saint-pierre-sur-dives:hover {
    background-image: url(/static/media/saint-pierre-sur-dives.64964c40.jpg);
}

.hippo_vire:hover {
    background-image: url(/static/media/vire.0eaf4ea5.jpg);
}

.hippo_mons:hover {
    background-image: url(/static/media/mons.3ad498c8.jpg);
}

.hippo_abbeville:hover {
    background-image: url(/static/media/abbeville.8b7481fc.jpg);
}

.hippo_amiens:hover {
    background-image: url(/static/media/amiens.3008d794.jpg);
}

.hippo_arras:hover {
    background-image: url(/static/media/arras01.0b9cdb36.jpg);
}

.hippo_la_capelle:hover {
    background-image: url(/static/media/la_capelle.7ffc4de5.jpg);
}

.hippo_le_croise:hover {
    background-image: url(/static/media/le_croise.b2f1e1f3.jpg);
}

.hippo_le_touquet:hover {
    background-image: url(/static/media/le_touquet.93b639bf.jpg);
}

.hippo_saint-omer:hover {
    background-image: url(/static/media/saint-omer.7b4f18db.jpg);
}

.hippo_reims:hover {
    background-image: url(/static/media/reims.969a8409.jpg);
}

.hippo_strasbourg:hover {
    background-image: url(/static/media/strasbourg.050c56b9.jpg);
}

.hippo_maure_de_bretagne:hover {
    background-image: url(/static/media/maure_de_bretagne.a89be2d2.jpg);
}

.hippo_nantes:hover {
    background-image: url(/static/media/nantes.368f821a.jpg);
}

.hippo_saint-malo:hover {
    background-image: url(/static/media/saint-malo.d36e1c48.jpg);
}

.hippo_segre:hover {
    background-image: url(/static/media/segre01.28ec9fd7.jpg);
}


/* Régions ------------------------------------------------------------------------ */

.divRegions {
    display: flex;
    min-height: 93vh;
    background-color: rgb(148, 140, 129);
    background-color: var(--background-color);
    padding-bottom: 20%;
}

@media screen and (min-width: 1026px) {
    .divRegions {
        padding-bottom: 5%;
    }
}

.titleRegions {
    font-family: 'Oleo Script', sans-serif;
    margin-bottom: 2%;
}

.linkRegion {
    margin: auto;
    width: 70%;
    height: 75px;
    display: flex;
    margin-bottom: 2%;
    border-radius: 10px;
    border: solid 2px white;
    color: black;
    font-size: 1.5em;
    font-family: 'Oleo Script', sans-serif;
}

.linkRegion:hover {
    text-decoration: none;
}

@media screen and (min-width: 768px) {
    .linkListe {
        height: 100px;
        margin-bottom: 1%;
    }
}

.linkListe:hover {
    text-decoration: none;
    background-color: black;
    color: white;
}

.linkRegionHauteNormandie {
    background-color: rgb(40, 40, 204);
    color: white;
}

.linkRegionHauteNormandie:hover {
    color: yellow;
}

@media screen and (min-width: 1026px) {
    .linkRegionHauteNormandie {
        position: absolute;
        top: 220px;
        left: 550px;
        width: 100px;
        height: 70px;
        font-size: 0.8em;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionHauteNormandie {
        top: 280px;
    }
}

.titleLinkRegion {
    margin: auto;
}

.linkRegionNord {
    background-color: rgb(21, 146, 21);
    color: white;
}

.linkRegionNord:hover {
    color: yellow;
}

@media screen and (min-width: 1026px) {
    .linkRegionNord {
        position: absolute;
        top: 170px;
        left: 600px;
        width: 100px;
        height: 40px;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionNord {
        top: 230px;
    }
}

.linkRegionBasseNormandie {
    background-color: rgb(238, 142, 32);
    color: white;
}

@media screen and (min-width: 1026px) {
    .linkRegionBasseNormandie {
        position: absolute;
        top: 250px;
        left: 440px;
        width: 100px;
        height: 40px;
        font-size: 0.8em;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionBasseNormandie {
        top: 310px;
    }
}

.linkRegionAnjouMaineCentreOuest {
    background-color: rgb(223, 223, 0);
    color: black;
}

@media screen and (min-width: 1026px) {
    .linkRegionAnjouMaineCentreOuest {
        position: absolute;
        top: 300px;
        left: 450px;
        width: 160px;
        height: 90px;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionAnjouMaineCentreOuest {
        top: 360px;
    }
}

.linkRegionOuest {
    background-color: rgb(199, 0, 0);
    color: white;
}

.linkRegionOuest:hover {
    color: yellow;
}

@media screen and (min-width: 1026px) {
    .linkRegionOuest {
        position: absolute;
        top: 300px;
        left: 390px;
        width: 50px;
        height: 90px;
        font-size: 0.9em;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionOuest {
        top: 360px;
    }
}

.linkRegionEst {
    background-color: purple;
    color: white;
}

.linkRegionEst:hover {
    color: yellow;
}

@media screen and (min-width: 1026px) {
    .linkRegionEst {
        position: absolute;
        top: 220px;
        left: 660px;
        width: 170px;
        height: 70px;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionEst {
        top: 280px;
    }
}

.linkRegionBelgique {
    background-color: rgb(143, 91, 31);
    color: white;
}

.linkRegionBelgique:hover {
    color: yellow;
}

@media screen and (min-width: 1026px) {
    .linkRegionBelgique {
        position: absolute;
        top: 140px;
        left: 710px;
        width: 75px;
        height: 55px;
        font-size: 1em;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionBelgique {
        top: 200px;
    }
}

@media screen and (min-width: 1026px) {
    .linkRegionCentreEst {
        position: absolute;
        top: 300px;
        left: 620px;
        width: 190px;
        height: 90px;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionCentreEst {
        top: 360px;
    }
}

@media screen and (min-width: 1026px) {
    .linkRegionSudOuest {
        position: absolute;
        top: 400px;
        left: 440px;
        width: 200px;
        height: 100px;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionSudOuest {
        top: 460px;
    }
}

@media screen and (min-width: 1026px) {
    .linkRegionSudEst {
        position: absolute;
        top: 400px;
        left: 650px;
        width: 180px;
        height: 70px;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionSudEst {
        top: 460px;
    }
}

@media screen and (min-width: 1026px) {
    .linkRegionCorse {
        position: absolute;
        top: 490px;
        left: 800px;
        width: 25px;
        height: 30px;
        font-size: 0.5em;
    }
}

@media screen and (min-width: 1400px) {
    .linkRegionCorse {
        top: 550px;
    }
}
